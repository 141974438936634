<template>
  <v-card>
    <v-card-title>
      {{ "Users" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-list rounded>
        <v-list-item :class="`primary--text font-weight-medium`">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiFileDocumentCheck }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>File Exists</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>
      <v-list rounded>
        <v-list-item :class="`error--text font-weight-medium`">
          <v-list-item-icon>
            <v-icon>{{ icons.mdiFileDocumentCheck }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>File Not Uploaded</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="browse"
        :search="search"
        :loading="loading"
        loading-text="LOADING.... PLEASE WAIT"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.index="{ item }">
          {{ item.index }}
        </template>
        <template #[`item.first_name`]="{item}">
          {{ `${item.first_name} ${item.last_name}` }}
        </template>
        <template #[`item.verification_doc`]="{item}">
          <a v-if="item.verification_doc" :href="`${media_path}${item.verification_doc}`" target="_blank">
            <v-btn icon :class="`primary--text font-weight-medium`">
              <v-icon>{{ icons.mdiFileDocumentCheck }}</v-icon>
            </v-btn>
          </a>
          <span v-else>
            <v-btn icon :class="`error--text font-weight-medium`">
              <v-icon>{{ icons.mdiFileDocumentRemove }}</v-icon>
            </v-btn>
          </span>
        </template>
        <template #[`item.approved`]="{item}">
          <v-switch v-model="item.approved" inset :label="``" @change="approve(item)"></v-switch>
        </template>
        <template #[`item.pilot_license_expiry`]="{item}">
          <span :class="`${item.pilot_status}--text font-weight-medium`">
            {{ item.pilot_license_expiry }}
          </span>
        </template>
        <template #[`item.medical`]="{item}">
          <span :class="`${item.medical_status}--text font-weight-medium`">
            {{ item.medical }}
          </span>
        </template>
        <template #[`item.avsec`]="{item}">
          <span :class="`${item.avsec_status}--text font-weight-medium`">
            {{ item.avsec }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiPencil, mdiDelete, mdiFileDocumentCheck, mdiFileDocumentRemove } from "@mdi/js";
import Request from "@/core/api/request";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import util from "@/core/utils/misc";

export default {
  name: "UserBrowse",
  directives: { permission },

  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Full Name", value: "first_name", sortable: true },
        { text: "License Number", value: "pilot_license_number", sortable: true },
        { text: "Document", value: "verification_doc", sortable: false },
        { text: "Approved", value: "approved", sortable: true },
        { text: "License Expiry", value: "pilot_license_expiry", sortable: true },
        { text: "Medical", value: "medical", sortable: true },
        { text: "Avsec", value: "avsec", sortable: true },
      ],
      icons: {
        mdiPencil,
        mdiDelete,
        mdiFileDocumentCheck,
        mdiFileDocumentRemove,
      },
    };
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
    media_path() {
      return this.$MEDIA;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.USERS__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data.data;
      this.loading = false;
    },

    async approve(item) {
      util.isLoading({ active: true, title: "Wait while we process." });
      await Request.update(`${this.$IYA.URI.USERS__APPROVE}`, item);
      this.initialize();
      util.isLoading();
    },

    // read(param) {
    //   this.$router.push({ name: "User-Read", params: { id: param } });
    // },
    // add() {
    //   this.$router.push({ name: "User-Add" });
    // },

    // trash(id) {
    //   this.$swal({
    //     title: `Are you sure?`,
    //     text: `You can't revert your action`,
    //     type: `warning`,
    //     showCancelButton: true,
    //     confirmButtonText: `Yes Delete it!`,
    //     cancelButtonText: `No, Keep it!`,
    //     showCloseButton: true,
    //     showLoaderOnConfirm: true,
    //   }).then(result => {
    //     if (result.value) {
    //       this.destroy(id);
    //     } else {
    //       this.$swal(`Cancelled`, `Your records are still intact`, `info`);
    //     }
    //   });
    // },

    // async destroy(id) {
    //   await Request.post(`${this.$IYA.URI.USERS__DELETE}`, { id: id }).then(response => {
    //     if (response.status !== this.$IYA.HTTP.OK) {
    //       this.$swal(`Failed`, `Failed to remove record!`, `error`);
    //       return;
    //     }
    //     this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
    //     this.initialize();
    //   });
    // },
  },
};
</script>
