var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s("Users")+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-list',{attrs:{"rounded":""}},[_c('v-list-item',{class:"primary--text font-weight-medium"},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileDocumentCheck))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("File Exists")])],1)],1)],1),_c('v-spacer'),_c('v-list',{attrs:{"rounded":""}},[_c('v-list-item',{class:"error--text font-weight-medium"},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileDocumentCheck))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("File Not Uploaded")])],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.browse,"search":_vm.search,"loading":_vm.loading,"loading-text":"LOADING.... PLEASE WAIT"},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.first_name) + " " + (item.last_name)))+" ")]}},{key:"item.verification_doc",fn:function(ref){
var item = ref.item;
return [(item.verification_doc)?_c('a',{attrs:{"href":("" + _vm.media_path + (item.verification_doc)),"target":"_blank"}},[_c('v-btn',{class:"primary--text font-weight-medium",attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileDocumentCheck))])],1)],1):_c('span',[_c('v-btn',{class:"error--text font-weight-medium",attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileDocumentRemove))])],1)],1)]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","label":""},on:{"change":function($event){return _vm.approve(item)}},model:{value:(item.approved),callback:function ($$v) {_vm.$set(item, "approved", $$v)},expression:"item.approved"}})]}},{key:"item.pilot_license_expiry",fn:function(ref){
var item = ref.item;
return [_c('span',{class:((item.pilot_status) + "--text font-weight-medium")},[_vm._v(" "+_vm._s(item.pilot_license_expiry)+" ")])]}},{key:"item.medical",fn:function(ref){
var item = ref.item;
return [_c('span',{class:((item.medical_status) + "--text font-weight-medium")},[_vm._v(" "+_vm._s(item.medical)+" ")])]}},{key:"item.avsec",fn:function(ref){
var item = ref.item;
return [_c('span',{class:((item.avsec_status) + "--text font-weight-medium")},[_vm._v(" "+_vm._s(item.avsec)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }